import React from "react";
import Helmet from "react-helmet";

const DEFAULT_TITLE = "Chicken Soup for the Soul Entertainment";

const getTitle = (page_data, title_override) => {
    if (!page_data && !title_override) {
        return DEFAULT_TITLE
    } else if (title_override) {
        return title_override + " | " + DEFAULT_TITLE
    } else if (page_data.title) {
        if (typeof page_data.title === "object") {
            if (page_data.title.text) {
                return page_data.title.text + " | " + DEFAULT_TITLE
            } else {
                return DEFAULT_TITLE
            }
        } else {
            return page_data.title + " | " + DEFAULT_TITLE
        }
    } else if (page_data.heading) {
        return page_data.heading + " | " + DEFAULT_TITLE
    } else if (page_data.name) {
        return page_data.name + " | " + DEFAULT_TITLE
    } else {
        return DEFAULT_TITLE
    }
}
const HelmetInfo = ({page_data, title_override}) => {
  return (
    <Helmet>
      <meta
        name="description"
        content="Chicken Soup for the Soul Entertainment"
      />
      <meta name="author" content="Chicken Soup for the Soul Entertainment" />
      <title>{getTitle(page_data, title_override)}</title>
      <html lang="en" />
      <link rel="stylesheet" href="https://use.typekit.net/vux0knl.css" />
    </Helmet>
  );
};

export default HelmetInfo;
