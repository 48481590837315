import { createGlobalStyle } from "styled-components";
import modernNormalize from "styled-modern-normalize";

const GlobalStyle = createGlobalStyle`
  ${modernNormalize}

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'effra', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${(p) => p.theme.textColor};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'effra', sans-serif;
    margin: 0;
    color: ${(p) => p.theme.greyishBlue};
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    cursor: pointer;
  }

  img {
    vertical-align: middle;
  }

  figure {
    margin: 0;
  }

  .js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  }

  input[type='text'],
  input[type='email'] {
    appearance: none; /* for iOS */
  }

  textarea {
    appearance: none; /* for iOS */
  }
`;

export default GlobalStyle;
