const theme = {
  textColor: "#323232",
  white: "#FFFFFF",
  primaryColor: "#FF5252",
  greyishBlue: "#425563",
  lightGrey: "#D8D8D8",
  lightMidGrey: "#CACACA",
  midGrey: "#9B9B9B",
  darkGrey: "#4A4A4A",
  lightBlue: "#EBF3F5",
  red: "#E4002B",
  maxPageWidth: "1264px",
  paddingPageSm: "32px",
  paddingPageLg: "50px",
  breakpointMd: "980px",
};

export default theme;
