import styled, { css } from "styled-components";

const Container = styled.div`
  max-width: ${(p) => p.theme.maxPageWidth};
  ${(p) => p.fullwidth && "max-width: 100vw;"}
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(p) => p.theme.paddingPageSm};
  padding-right: ${(p) => p.theme.paddingPageSm};
  ${(p) => p.fullwidth && "padding: 0px;"}
  ${(p) => p.fullwidthMobile && "padding: 0px;"}
  ${(p) =>
    p.noPadding &&
    css`
      max-width: 1164px;
      padding-left: 0px;
      padding-right: 0px;
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: ${(p) => p.theme.paddingPageLg};
    padding-right: ${(p) => p.theme.paddingPageLg};
    ${(p) => p.fullwidth && "padding: 0px;"}
    ${(p) =>
      p.noPadding &&
      css`
        max-width: 1164px;
        padding-left: 0px;
        padding-right: 0px;
      `}
  }
`;

export default Container;
