import 'focus-visible'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from './src/styles/theme'
import GlobalStyle from './src/styles/GlobalStyle'

import HelmetInfo from './src/components/HelmetInfo'
import Layout from './src/components/Layout'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <HelmetInfo />
        {element}
      </React.Fragment>
    </ThemeProvider>
  )
}

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}
