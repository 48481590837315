import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Container from "../styles/Container";

import logo from "../images/css-entertainment-logo-white.png";
import twitterIcon from "../images/twitter-icon-light.png";
import linkedinIcon from "../images/linkedin-icon-light.png";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.greyishBlue};
`;

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 32px;
    padding-bottom: 30px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    align-items: flex-start;
    flex-direction: row;
  }
`;
const Bottom = styled.div``;

const Logo = styled.img`
  width: 118px;
  margin-bottom: 22px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
  }
`;

const SocialMediaContainer = styled.ul`
  display: flex;
  margin-bottom: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 6px;
    margin-bottom: 0px;
  }
`;

const SocialMediaItem = styled.li`
  :not(:last-child) {
    margin-right: 20px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-right: 18px;
    }
  }
`;

const SocialMediaIcon = styled.img`
  width: 43px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 37px;
  }
`;

const BoxContainer = styled.div`
  order: 1;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    order: 0;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 23px;
`;

const Heading = styled.h1`
  font-family: "effra", sans-serif;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    /* font-size: 14px; */
    margin-bottom: 0px;
  }
`;

const MenuContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`;

const Menu = styled.ul`
  display: flex;
  justify-content: center;
`;

const MenuItem = styled.li`
  font-size: 14px;
  line-height: 30px;
  :hover {
    text-decoration: underline;
  }
`;

const Divider = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  font-size: 12px;
`;

const DividerDesktop = styled(Divider)`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`;

const Copyright = styled.div`
  margin-top: 33px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
  div:first-child {
    margin-right: 4px;
  }
`;

const Footer = () => {
  return (
    <OuterContainer>
      <Container>
        <Wrapper>
          <Top>
            <Link to="/">
              <Logo src={logo} alt="Logo" />
            </Link>
            <BoxContainer>
              <Box>
                <Heading>Chicken Soup for the Soul Entertainment</Heading>
                <MenuContainer>
                  <Menu>
                    <MenuItem>
                      <Link to="/about-us">About Us</Link>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <a href="https://www.redbox.com/careers">
                        Careers
                      </a>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <Link to="/executive-leadership">Our Team</Link>
                    </MenuItem>
                  </Menu>
                  <DividerDesktop>|</DividerDesktop>
                  <Menu>
                    <MenuItem>
                      <Link to="/networks">Networks</Link>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <Link to="/distribution-and-production">
                        Distribution & Production
                      </Link>
                    </MenuItem>
                  </Menu>
                  <DividerDesktop>|</DividerDesktop>
                  <Menu>
                    <MenuItem>
                      <Link to="/news">News</Link>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <a href="https://ir.cssentertainment.com/">
                        Investor Relations
                      </a>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <Link to="/about-us/#contact">Contact Us</Link>
                    </MenuItem>
                  </Menu>
                </MenuContainer>
              </Box>

              <Box>
                <Heading>Our Parent Company</Heading>
                <MenuContainer>
                  <Menu>
                    <MenuItem>
                      <a href="https://www.chickensoup.com/">Books</a>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <a href="https://chickensouppets.com/">Pet Food</a>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <a href="https://www.chickensoup.com/about/faq">FAQ</a>
                    </MenuItem>
                  </Menu>
                  <DividerDesktop>|</DividerDesktop>
                  <Menu>
                    <MenuItem>
                      <a href="https://www.chickensoup.com/privacy-policy">
                        Privacy Policy
                      </a>
                    </MenuItem>
                    <Divider>|</Divider>
                    <MenuItem>
                      <a href="https://www.chickensoup.com/privacy-notice-california">
                        California Privacy Notice
                      </a>
                    </MenuItem>
                  </Menu>
                  <DividerDesktop>|</DividerDesktop>
                  <Menu>
                    <MenuItem>
                      <a href="https://www.chickensoup.com/terms-of-use">
                        Terms + Conditions
                      </a>
                    </MenuItem>
                  </Menu>
                </MenuContainer>
              </Box>
            </BoxContainer>
            <SocialMediaContainer>
              <SocialMediaItem>
                <a
                  href="https://twitter.com/CSSEntertain"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMediaIcon src={twitterIcon} alt="Twitter" />
                </a>
              </SocialMediaItem>
              <SocialMediaItem>
                <a
                  href="https://www.linkedin.com/company/cssentertainment"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMediaIcon src={linkedinIcon} alt="LinkedIn" />
                </a>
              </SocialMediaItem>
            </SocialMediaContainer>
          </Top>
          <Bottom>
            <Copyright>
              <div>
                © Chicken Soup for the Soul Entertainment, Inc.{" "}
                {new Date().getFullYear()}.
              </div>
              <div> All rights reserved.</div>
            </Copyright>
          </Bottom>
        </Wrapper>
      </Container>
    </OuterContainer>
  );
};

export default Footer;
