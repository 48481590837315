import React, { useState } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { Location } from "@reach/router";

import Container from "../styles/Container";

import logo from "../images/css-entertainment-logo.png";
import twitterIcon from "../images/twitter-icon-dark.png";
import linkedinIcon from "../images/linkedin-icon-dark.png";

const OuterOuterContainer = styled.div`
  margin-top: 83px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 112px;
  }
`;

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
  z-index: 2;
  overflow: auto;
  ${(p) =>
    !p.mobileMenuIsHidden &&
    css`
      background-color: #eee;
      bottom: 0;
      left: 0;
      right: 0;
      @media (min-width: ${(p) => p.theme.breakpointMd}) {
        background-color: white;
        bottom: initial;
        left: initial;
        right: initial;
        ${(p) => p.showSpecialNavigation && "overflow: initial;"}
      }
    `}
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  ${(p) => p.showSpecialNavigation && "justify-content: stretch;"}
`;

const Logo = styled.img`
  height: 55px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 84px;
  }
`;

const Menu = styled.ul`
  display: none;
  ${(p) => p.showSpecialNavigation && "display: flex; margin-left: auto;"}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`;

const MenuItem = styled.li`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  :not(:last-child) {
    margin-right: 35px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 19px;
    line-height: 24px;
  }
`;

const BurgerMenuContainer = styled.div`
  height: 22px;
  margin-left: auto;
  cursor: pointer;
  ${(p) => p.showSpecialNavigation && "margin-left: 24px;"}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
    ${(p) => p.showSpecialNavigation && "display: block;"}
    position: relative;
    z-index: 1;
  }
`;

const BurgerMenu = styled.span`
  display: block;
  width: 24px;
  height: 2px;
  background-color: ${(p) => p.theme.textColor};
  border-radius: 10px;
  transform: translateY(10px);
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: ${({ mobileMenuIsHidden }) =>
    !mobileMenuIsHidden && "transparent"};
  &:before {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: ${(p) => p.theme.textColor};
    transform: translateY(-10px);
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: ${({ mobileMenuIsHidden }) =>
      !mobileMenuIsHidden && "translateY(0px) rotate(45deg)"};
    background-color: ${({ mobileMenuIsHidden, theme }) =>
      !mobileMenuIsHidden && theme.textColor};
  }
  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: ${(p) => p.theme.textColor};
    transform: translateY(8px);
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: ${({ mobileMenuIsHidden }) =>
      !mobileMenuIsHidden && "translateY(-2px) rotate(-45deg)"};
    background-color: ${({ mobileMenuIsHidden, theme }) =>
      !mobileMenuIsHidden && theme.textColor};
  }
`;

const MobileMenu = styled.div`
  display: ${(p) => (p.mobileMenuIsHidden ? "none" : "block")};
  margin-bottom: 37px;
  /* font-weight: 500; */
  /* height: ${(p) => (p.mobileMenuIsHidden ? "0vh" : "calc(100vh - 72px)")}; */
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    /* display: none; */
    display: ${(p) => (p.mobileMenuIsHidden ? "none" : "flex")};
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #eee;
    padding-top: 70px;
    padding-bottom: 37px;
    margin-bottom: 0px;
    overflow: auto;
  }
`;

const MobileList = styled.ul`
  margin-top: 48px;
`;

const MobileListItem = styled.li`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 36px;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    text-align: left;
    margin-left: 40px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialMediaContainer = styled.ul`
  display: flex;
  flex-shrink: 0;
  margin-top: 32px;
  margin-bottom: 22px;
`;

const SocialMediaItem = styled.li`
  :not(:last-child) {
    margin-right: 20px;
  }
`;

const SocialMediaIcon = styled.img`
  width: 34px;
`;

const Copyright = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`;

const Navbar = () => {
  const [mobileMenuIsHidden, setMobileMenuIsHidden] = useState(true);
  const toggleMobileMenu = () => {
    setMobileMenuIsHidden((s) => !s);
  };
  const closeMobileMenu = () => {
    setMobileMenuIsHidden(true);
  };
  return (
    <Location>
      {({ location: { pathname } }) => {
        const showSpecialNavigation =
          pathname.includes("network/") ||
          pathname.includes("productions/") ||
          pathname.includes("series/") ||
          pathname.includes("distribution/");

        return (
          <OuterOuterContainer>
            <OuterContainer
              mobileMenuIsHidden={mobileMenuIsHidden}
              showSpecialNavigation={showSpecialNavigation}
            >
              <Container>
                <NavbarContent showSpecialNavigation={showSpecialNavigation}>
                  <Link to="/" onClick={closeMobileMenu}>
                    <Logo src={logo} alt="Logo" />
                  </Link>
                  <Menu showSpecialNavigation={showSpecialNavigation}>
                    {!showSpecialNavigation ? (
                      <>
                        <MenuItem>
                          <Link to="/about-us">About Us</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/networks">Networks</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/distribution-and-production">
                            Distribution & Production
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/news">News</Link>
                        </MenuItem>
                        <MenuItem>
                          <a
                            href="https://ir.cssentertainment.com/"
                            onClick={closeMobileMenu}
                          >
                            Investor Relations
                          </a>
                        </MenuItem>
                      </>
                    ) : pathname.includes("network/") ? (
                      <>
                        <MenuItem style={{ textAlign: "right" }}>
                          NETWORKS
                        </MenuItem>
                      </>
                    ) : (
                      (pathname.includes("productions/") ||
                        pathname.includes("series/") ||
                        pathname.includes("distribution/")) && (
                        <>
                          <MenuItem style={{ textAlign: "right" }}>
                            DISTRIBUTION & PRODUCTION
                          </MenuItem>
                        </>
                      )
                    )}
                  </Menu>
                  <BurgerMenuContainer
                    role="button"
                    tabIndex="0"
                    aria-label="Burger Menu"
                    onClick={toggleMobileMenu}
                    onKeyPress={toggleMobileMenu}
                    showSpecialNavigation={showSpecialNavigation}
                  >
                    <BurgerMenu mobileMenuIsHidden={mobileMenuIsHidden} />
                  </BurgerMenuContainer>
                </NavbarContent>
                <MobileMenu mobileMenuIsHidden={mobileMenuIsHidden}>
                  <MobileList>
                    <MobileListItem>
                      <Link to="/about-us" onClick={closeMobileMenu}>
                        About Us
                      </Link>
                    </MobileListItem>
                    <MobileListItem>
                      <Link to="/networks" onClick={closeMobileMenu}>
                        Networks
                      </Link>
                    </MobileListItem>
                    <MobileListItem>
                      <Link
                        to="/distribution-and-production"
                        onClick={closeMobileMenu}
                      >
                        Distribution & Production
                      </Link>
                    </MobileListItem>
                    <MobileListItem>
                      <Link to="/news" onClick={closeMobileMenu}>
                        News
                      </Link>
                    </MobileListItem>
                    <MobileListItem>
                      <a
                        href="https://ir.cssentertainment.com/"
                        onClick={closeMobileMenu}
                      >
                        Investor Relations
                      </a>
                    </MobileListItem>
                    <MobileListItem>
                      <Link to="/about-us/#contact" onClick={closeMobileMenu}>
                        Contact Us
                      </Link>
                    </MobileListItem>
                  </MobileList>
                  <Bottom>
                    <SocialMediaContainer>
                      <SocialMediaItem>
                        <a
                          href="https://twitter.com/CSSEntertain"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SocialMediaIcon src={twitterIcon} alt="Twitter" />
                        </a>
                      </SocialMediaItem>
                      <SocialMediaItem>
                        <a
                          href="https://www.linkedin.com/company/cssentertainment"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SocialMediaIcon src={linkedinIcon} alt="LinkedIn" />
                        </a>
                      </SocialMediaItem>
                    </SocialMediaContainer>
                    <Copyright>
                      <div>
                        © Chicken Soup for the Soul Entertainment, Inc.{" "}
                        {new Date().getFullYear()}.
                      </div>
                      <div> All rights reserved.</div>
                    </Copyright>
                  </Bottom>
                </MobileMenu>
              </Container>
            </OuterContainer>
          </OuterOuterContainer>
        );
      }}
    </Location>
  );
};

export default Navbar;
